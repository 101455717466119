export class AppConstant {
  public static LOGIN_SUCCESS = 'Logged in successfully';
  public static LOGIN_ERROR = 'Error while logging in';
  public static RESET_PASSWORD_SUCCESS = 'Password changed successfully';
  public static SERVER_ERROR = 'Something went wrong, Please try again later';
  public static USER_DELETE_SUCCESS = 'User deleted successfully';
  public static USER_BLOCKED_SUCCESS = 'User blocked successfully';
  public static USER_UNBLOCKED_SUCCESS = 'User unblocked successfully';
  public static IMAGE_UPLOAD_ERROR = 'File format not valid';
  public static NO_INTERNET_CONNECTION =
    'No Internet Connectivity. Please recheck and try again.';
  public static PAGE_SIZE = 10;
}
