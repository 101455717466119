import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  @Output()
  appHeader: EventEmitter<any> = new EventEmitter(true);
  sidebar: EventEmitter<any> = new EventEmitter(true);
  loggedInUserName: EventEmitter<any> = new EventEmitter(true);
  constructor() {}
}
