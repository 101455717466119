import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import { SettingsModule } from './settings';
import { StaticModule } from './static';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EventsService } from '@app/events/events.service';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { AppConstant } from '@app/app.constant';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '@app/shared/spinner/spinner.module';

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,
    // core & shared
    CoreModule,
    SharedModule,
    // features
    StaticModule,
    SettingsModule,
    ReactiveFormsModule,
    // app
    AppRoutingModule,
    SpinnerModule
  ],
  declarations: [AppComponent],
  providers: [
    EventsService,
    AppConstant,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, disableClose: true }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
