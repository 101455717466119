import browser from 'browser-detect';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material';
import * as _ from 'lodash';

import {
  ActionAuthLogin,
  ActionAuthLogout,
  routeAnimations,
  AppState,
  LocalStorageService,
  selectIsAuthenticated
} from '@app/core';
import { environment as env } from '@env/environment';

import {
  ActionSettingsChangeLanguage,
  ActionSettingsChangeAnimationsPageDisabled,
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader
} from './settings';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { EventsService } from '@app/events/events.service';
import { style } from '@angular/animations';

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav')
  sidenav: MatSidenav;
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = require('../assets/kiin_logo_white.png');
  loggedInUserDetails: any;
  loggedInUserFirstName: any;
  showBurgerMenuIcon: Boolean = false;
  hideSideNav = false;

  navigation = [
    // side navbar lists
    {
      link: 'all-users',
      label: 'User Management',
      active: false,
      opened: false,
      subItems: [
        {
          link: 'un-blocked-users',
          label: 'Unblocked Users',
          parent: 'all-users',
          type: 'active',
          active: false
        },
        {
          link: 'blocked-users',
          label: 'Blocked Users',
          parent: 'all-users',
          type: 'inactive',
          active: false
        }
      ]
    },
    {
      link: 'reports',
      label: 'Reports',
      active: false,
      opened: false,
      subItems: []
    },
    {
      link: 'profile',
      label: 'Profile',
      active: false,
      opened: false,
      subItems: []
    },
    {
      link: 'terms-of-use',
      label: 'Terms of Use',
      active: false,
      opened: false,
      subItems: []
    },
    {
      link: 'privacy',
      label: 'Privacy Policy',
      active: false,
      opened: false,
      subItems: []
    }
  ];
  navigationSideMenu = [...this.navigation];
  isAuthenticated$: Observable<boolean>;
  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;
  appHeader: String = '';
  isLoggedIn: any = false;

  constructor(
    private store: Store<AppState>,
    private storageService: LocalStorageService,
    private router: Router,
    private events: EventsService,
    private activatedRoutes: ActivatedRoute
  ) {
    this.events.appHeader.subscribe(item => {
      if (item) {
        this.appHeader = item;
      }
    });
    this.events.loggedInUserName.subscribe(item => {
      if (item) {
        this.loggedInUserFirstName = item;
      }
    });
    this.events.sidebar.subscribe(item => {
      if (item) {
        this.sidenav.open();
      } else {
        this.sidenav.close();
      }
    });
    setTimeout(() => {
      const routerEvent = this.router.url.split('/');
      this.setNavBarList(routerEvent[1], false);
    }, 1000);
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  /**
   * Called automatically on initialize
   */
  ngOnInit(): void {
    this.hideSideNav =
      window.location.href.includes('reset-password') ||
      window.location.href.includes('forgot-password');
    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        new ActionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
    this.isLoggedIn = this.storageService.get('KIIN-AUTH');
    this.loggedInUserDetails = this.storageService.getItem('user-details');
    if (this.loggedInUserDetails && this.loggedInUserDetails.firstName) {
      this.loggedInUserFirstName = this.loggedInUserDetails.firstName;
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    const isMenuShouldCollapsed = this._shouldMenuCollapse();
    if (isMenuShouldCollapsed) {
      this.sidenav.close();
    }
    this.showBurgerMenuIcon =
      this.router.url === '/privacy' || this.router.url === '/terms-of-use';
    if (this.showBurgerMenuIcon && window.innerWidth < 768) {
      this.sidenav.close();
      setTimeout(() => {
        if (
          this.showBurgerMenuIcon &&
          window.innerWidth < 768 &&
          this.isLoggedIn
        ) {
          const data = document.querySelector('.toolbar') as HTMLElement;
          data.style.display = 'none';
        }
      }, 700);
    }
  }

  private _shouldMenuCollapse(): boolean {
    return window.innerWidth <= 768;
  }

  /**
   * Called automatically after view is initialized
   */
  ngAfterViewInit(): void {
    this.events.appHeader.subscribe(item => {
      if (item) {
        this.appHeader = item;
      }
    });
    setTimeout(() => {
      window.innerWidth >= 768 && this.isLoggedIn && !this.hideSideNav
        ? this.sidenav.open()
        : this.sidenav.close();
      this.showBurgerMenuIcon =
        this.router.url === '/privacy' || this.router.url === '/terms-of-use';
      if (this.showBurgerMenuIcon) {
        this.sidenav.close();
      }
    }, 200);
    setTimeout(() => {
      if (this.showBurgerMenuIcon && this.isLoggedIn) {
        const data = document.querySelector('.toolbar') as HTMLElement;
        data.style.display = 'none';
      }
    }, 700);
  }

  /**
   * to login the user
   */
  onLoginClick() {
    this.store.dispatch(new ActionAuthLogin());
    this.router.navigate(['all-users']);
  }

  /**
   * logs out the current logged in user
   */
  onLogoutClick() {
    this.store.dispatch(new ActionAuthLogout());
    this.router.navigate(['sign-in']);
    this.sidenav.close();
  }

  // /**
  //  * called when clicked on the profile option
  //  */
  // onProfileClick() {
  //   this.router.navigate(['profile']);
  //   console.log('user profile clicked');
  // }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(new ActionSettingsChangeLanguage({ language }));
  }

  /**
   * Called when user clicks on the logo
   */
  onLogoClick() {
    this.router.navigate(['all-users']);
  }

  /**
   * to activate the child menus for current page in the side navbar
   * @param item
   */
  openChildMenu(item) {
    _.filter(this.navigation, data => {
      if (data.label === item.label) {
        data.opened = !data.opened;
      } else {
        data.opened = false;
      }
    });
  }

  /**
   * activates the selected component in the side navbar based on routes
   * @param currentPath
   * @param isOpen
   */
  setNavBarList(currentPath, isOpen) {
    _.filter(this.navigation, item => {
      if (item.link === currentPath) {
        isOpen ? (item.opened = true) : (item.active = true);
      } else {
        _.filter(item.subItems, data => {
          if (data.link === currentPath) {
            data.active = true;
            this.setNavBarList(data.parent, true);
          }
        });
      }
    });
  }
}
