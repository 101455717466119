import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsContainerComponent } from './settings';
import { AuthGuardService } from '@app/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    component: SettingsContainerComponent,
    data: { title: 'anms.menu.settings' }
  },
  {
    path: 'all-users',
    loadChildren: 'app/all-users/all-users.module#AllUsersModule',
    canActivate: [AuthGuardService]
  },
  // {
  //   path: 'all-users/:status',
  //   loadChildren: 'app/all-users/all-users.module#AllUsersModule',
  //   canActivate: [AuthGuardService]
  // },
  {
    path: 'reports',
    loadChildren: 'app/reports/reports.module#ReportsModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'sign-in',
    loadChildren: 'app/sign-in/sign-in.module#SignInModule'
  },
  {
    path: 'sign-up',
    loadChildren: 'app/sign-up/sign-up.module#SignUpModule'
  },
  {
    path: 'forgot-password',
    loadChildren:
      'app/forget-password/forget-password.module#ForgetPasswordModule'
  },
  {
    path: 'reset-password',
    loadChildren: 'app/reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: 'profile',
    loadChildren: 'app/profile/profile.module#ProfileModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'privacy',
    loadChildren: 'app/privacy-policy/privacy-policy.module#PrivacyPolicyModule'
  },
  {
    path: 'terms-of-use',
    loadChildren: 'app/terms-of-use/terms-of-use.module#TermsOfUseModule'
  },
  {
    path: 'user-profile/:id',
    loadChildren: 'app/user-profile/user-profile.module#UserProfileModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'blocked-users',
    loadChildren: 'app/all-users/all-users.module#AllUsersModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'un-blocked-users',
    loadChildren: 'app/all-users/all-users.module#AllUsersModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'reset-password/:id',
    loadChildren: 'app/reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: '**',
    redirectTo: 'all-users',
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
